import constantes from "../helpers/constanteHelper"

function Rodape({ resolucaoMobile }) {
    return (
        <div style={{ width: '100vw', padding: 0, margin: 0, background: constantes.CORES.CINZA_ESCURO_BG}} className='py-3 d-flex flex-column align-items-center'>
             <span style={{ fontSize: '16px', color: 'white', fontWeight: '200' }}>© Visiongen - Todos os direitos reservados</span>
             <span style={{ fontSize: '12px', color: 'white', fontWeight: '200' }}>Imagens por <a href="https://pixabay.com/">Pixabay</a> e <a href="https://www.freepik.com/">Freepik</a></span>
        </div>
    );
}

export default Rodape;
