import iconeWhatsApp from '../images/whatsapp.png'
import iconeEmail from '../images/email.png'
import iconeMapa from '../images/mapa.png'

import ContatoTile from './Tiles/ContatoTile'

function Contatos({ resolucaoMobile }) {
    return (
        <div id='contato' style={{ width: '100vw', padding: 0, margin: 0, background: 'white'}} className='d-flex flex-column align-items-center'>
            <div className='my-5 text-center d-flex flex-column align-items-center' style={{ width: '80vw' }}>
                <span className='reveal' style={{ fontSize: resolucaoMobile ? '38px' : '60px', color: 'black', fontWeight: '600' }}>Vamos conversar?</span>
                <span className='reveal' style={{ fontSize: resolucaoMobile ? '20px' : '25px', color: 'black', fontWeight: '200' }}>Entre em contato com a nossa equipe e saiba mais sobre como podemos te ajudar:</span>

                {/* Serviços */}

                <div style={{ width: '80vw', gap: resolucaoMobile ? '20px' : '80px' }} className='mt-3 reveal flex-wrap d-flex align-items-center justify-content-center'>
                    <a style={{textDecoration: 'none'}} href="https://wa.me/5531987881496" target='_blank'><ContatoTile icone={iconeWhatsApp} texto={'(31) 9 8788-1496'} resolucaoMobile={resolucaoMobile} /></a>
                    <ContatoTile icone={iconeEmail} texto={'comercial@visiongen.com.br'} resolucaoMobile={resolucaoMobile} />
                    <ContatoTile icone={iconeMapa} texto={'AV. José Candido da Silveira, nº 2100, sala 13, Bairro Horto Florestal - Belo Horizonte - MG'} resolucaoMobile={resolucaoMobile} />
                </div>
            </div>
        </div>
    );
}

export default Contatos;
