import { Image } from 'react-bootstrap'

function QuemSomosTile({ imagem, descricao, resolucaoMobile }) {

    return (
        <div style={{ width: '90%' }} className={`mt-5 d-flex ${resolucaoMobile ? 'flex-column' : ''} align-items-center justify-content-center`}>
            <Image className='mx-3' src={imagem} fluid />
            <span style={{ width: '80%', fontSize: resolucaoMobile ? '17px' : '23px', color: 'white', fontWeight: '200' }}>{descricao}</span>
        </div>
    );
}
export default QuemSomosTile;
