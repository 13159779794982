import '../styles/menu.css'

import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'

import Image from 'react-bootstrap/Image'

import OpcaoMenu from '../components/OpcaoMenu'

import constantes from '../helpers/constanteHelper'


import logo from '../images/logo-menu.png'

function Menu({ opcaoSelecionada, nivelAcesso }) {
    const navigate = useNavigate();


    const [width, setWidth] = useState(window.innerWidth);

    function observarMudancaWidth() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', observarMudancaWidth);
        return () => {
            window.removeEventListener('resize', observarMudancaWidth);
        }
    }, []);

    const resolucaoMobile = width <= constantes.RESPONSIVIDADE.LARGURA_MOBILE;

    const abrirMenu = () => {
        const nav = document.querySelector("nav")
        nav.classList.add('open-nav')
    }

    const fecharMenu = () => {
        const nav = document.querySelector("nav")
        nav.classList.add('closed-nav')
        nav.classList.remove('open-nav')
    }

    const itemMenuSair = <OpcaoMenu texto={'Área do cliente'} opcao={'areaCliente'} opcaoSelecionada={opcaoSelecionada} resolucaoMobile={resolucaoMobile}></OpcaoMenu>

    return (
        <>
            <header style={{ width: resolucaoMobile ? 'auto' : '95vw' }} className='mx-1 mt-2 d-flex align-items-center justify-content-space-evenly nav'>
                <div style={{ cursor: 'pointer', width: resolucaoMobile ? '60vw' : 'auto' }} >
                    <Image onClick={() => { navigate(`../`) }} style={{ cursor: 'pointer', width: resolucaoMobile ? '150px' : '160px' }} className='mx-3' src={logo} alt="logo" fluid />
                </div>

                <nav style={{ width: resolucaoMobile ? width * 0.85 : width * 0.6 }}>
                    <div className="close" onClick={fecharMenu}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                            <path d="M12.45 37.65 10.35 35.55 21.9 24 10.35 12.45 12.45 10.35 24 21.9 35.55 10.35 37.65 12.45 26.1 24 37.65 35.55 35.55 37.65 24 26.1Z" fill={constantes.CORES.BRANCO_PADRAO} />
                        </svg>
                    </div>

                    <div style={{ gap: 1 }} className={`${resolucaoMobile ? 'mt-3' : ''} animate__animated animate__fadeIn d-flex align-items-center justify-content-center nav`}>
                        <OpcaoMenu texto={'Serviços'} opcao={'servicos'} funcao={() => {
                            document.getElementById('servicos')?.scrollIntoView()
                            fecharMenu()
                        }} opcaoSelecionada={opcaoSelecionada} resolucaoMobile={resolucaoMobile}></OpcaoMenu>
                        <OpcaoMenu texto={'Quem somos'} opcao={'quemSomos'} funcao={() => {
                            document.getElementById('quem-somos')?.scrollIntoView()
                            fecharMenu()

                        }} opcaoSelecionada={opcaoSelecionada} resolucaoMobile={resolucaoMobile}></OpcaoMenu>
                        <OpcaoMenu texto={'Contato'} opcao={'contato'} funcao={() => {
                            document.getElementById('contato')?.scrollIntoView()
                            fecharMenu()

                        }} opcaoSelecionada={opcaoSelecionada} resolucaoMobile={resolucaoMobile}></OpcaoMenu>

                        {/* {resolucaoMobile ? itemMenuSair : <></>} */}
                        {<div></div>}


                    </div>
                </nav>
                {/* {resolucaoMobile ? <></> : itemMenuSair} */}
                {<div></div>}

                <div style={{ width: resolucaoMobile ? '20vw' : '100vw' }} className="menu d-flex align-items-center justify-content-end pe-3" onClick={abrirMenu}>
                    <svg className="menu" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                        <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" fill={constantes.CORES.BRANCO_PADRAO} />
                    </svg>

                </div>

            </header>

        </>



    );
}

export default Menu;