import imagemFundo from '../images/dots-bg.png'

import imagemPrimeiroServico from '../images/duroc-angus-servicos.png'
import imagemSegundoServico from '../images/servico-1.png'
import imagemTerceiroServico from '../images/exames.png'

import ServicoTile from './Tiles/ServicoTile'


function Servicos({ resolucaoMobile }) {
    return (
        <div id='servicos' style={{ width: '100vw', padding: 0, margin: 0, background: 'white' }} className='d-flex flex-column align-items-center'>

            <div className='my-5 d-flex flex-column align-items-center' style={{ width: resolucaoMobile ? '90vw' : '48vw' }}>
                <span style={{ textAlign: 'center', fontSize: resolucaoMobile ? '38px' : '60px', color: 'black', fontWeight: '600' }}>Comprometimento e eficácia.</span>
                <span style={{ textAlign: 'center', fontSize: resolucaoMobile ? '20px' : '25px', color: 'black', fontWeight: '200' }}>Conheça mais detalhes sobre nossos principais serviços:</span>

                {/* Serviços */}

                <div style={{width: '80vw', gap:  resolucaoMobile ? '30px' : '80px'}} className='mt-5 d-flex flex-wrap align-items-center justify-content-center'>
                    <ServicoTile nome={'Comprovação de raça de animais'} imagem={imagemPrimeiroServico} descricao={'Com nosso serviço de comprovação de raça de animais Duroc e Angus, você pode garantir a origem e procedência de seus animais.'} resolucaoMobile={resolucaoMobile}/>
                    <ServicoTile nome={'Banco de DNA'} imagem={imagemSegundoServico} descricao={'Oferecemos a segurança de preservar o legado genético dos seus animais, que pode ser utilizado futuramente para fins de identificação ou mesmo para preservação da linhagem.'} resolucaoMobile={resolucaoMobile}/>
                    {/* <ServicoTile nome={'Exames'} imagem={imagemTerceiroServico} descricao={'Oferecemos outros serviços de exames como o teste para alopécia (calvície) e também testes de nutrigenética.'} resolucaoMobile={resolucaoMobile}/> */}
                </div>

            </div>

        </div>
    );
}

export default Servicos;
