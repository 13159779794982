import { Image } from 'react-bootstrap'

function ContatoTile({ icone, texto, resolucaoMobile }) {

    return (
        <div style={{width: resolucaoMobile ? '90vw' : '280px'}} className='mt-5 d-flex flex-column align-items-center justify-content-center text-center'>
            <Image className='mx-3' src={icone} fluid />
            <span className='mt-3' style={{ fontSize: '20px', color: 'black' }}>{texto}</span>
        </div>

    );
}
export default ContatoTile;
