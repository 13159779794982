const constantes = {

    RESPONSIVIDADE: {
        LARGURA_MOBILE: 1200
    },

    CORES: {
        VERDE_PRIMARIO: '#3A7F0D',
        AZUL_PRIMARIO: '#579ADA',
        SALMAO_PRIMARIO: '#FF5F5D',

        CINZA_ESCURO_BG: '#292929',

        BRANCO_PADRAO: 'white',
        PRETO_PADRAO: 'black',
        CINZA_PADRAO: 'grey',
        TRANSPARENTE: 'transparent',

        VERMELHO_PADRAO: '#E35252',
        VERDE_SUCESSO_PADRAO: '#58BF8E'
    },
}

export default constantes