import { Image } from 'react-bootstrap'

import constantes from '../helpers/constanteHelper'

import logo from '../images/logo-quem-somos.png'

import iconeQuemSomosInvestimento from '../images/quem-somos-icone-investimento.png'
import iconeQuemSomosTech from '../images/quem-somos-icone-tech.png'
import iconeQuemSomosDna from '../images/quem-somos-icone-dna.png'

import QuemSomosTile from './Tiles/QuemSomosTile'


function QuemSomos({ resolucaoMobile }) {
    return (
        <div id='quem-somos' style={{ width: '100vw', padding: 0, margin: 0, background: constantes.CORES.CINZA_ESCURO_BG }} className='d-flex flex-wrap justify-content-center'>
            <div className={`${resolucaoMobile ? 'mt-5': ''} reveal d-flex justify-content-center align-items-center`} style={{ width: resolucaoMobile ? '90vw' : '45vw', justifySelf: 'center' }}>
                <Image style={{ width: resolucaoMobile ? '250px' : '442px' }} className='mx-3' src={logo} fluid />
            </div>
            <div className='reveal my-5 d-flex flex-column align-items-center text-center' style={{ width: resolucaoMobile ? '90vw' : '48vw' }}>
                <span style={{ fontSize: resolucaoMobile ? '38px' : '60px', color: 'white', fontWeight: '600' }}>Muito mais que um laboratório.</span>
                <span style={{ fontSize: resolucaoMobile ? '20px' : '25px', color: 'white', fontWeight: '200' }}>A Visiongen é uma empresa de serviços genômicos especializada em análise e dados genéticos. O objetivo dessa iniciativa é oferecer aos clientes informações sobre a genética de seus animais.</span>

                {/* Serviços */}

                <QuemSomosTile imagem={iconeQuemSomosDna} descricao={'Dominamos o conhecimento das possibilidades genéticas.  Nossos resultados contribuem para sua tomada de decisão.'} resolucaoMobile={resolucaoMobile}/>
                <QuemSomosTile imagem={iconeQuemSomosInvestimento} descricao={'As comprovações genéticas podem permitir a exportação/certificação dos animais, abrindo novas possibilidades de receita.'} resolucaoMobile={resolucaoMobile}/>
                <QuemSomosTile imagem={iconeQuemSomosTech} descricao={'Priorizamos a alta tecnificação dos serviços. Utilizamos a tecnologia para gerar praticidade.'} resolucaoMobile={resolucaoMobile}/>
            </div>
        </div>
    );
}
export default QuemSomos;
