import { Route, Routes } from 'react-router-dom'

import Home from './pages/Home'

function Rotas() {
    return (
        <Routes>
            <Route exact path='/' element={<Home />}></Route>
           
            {/* Redirecionar rotas não existentes */}
            <Route exact path='*' element={<Home />}></Route>

        </Routes>
    )
}

export default Rotas