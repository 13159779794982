import constantes from '../helpers/constanteHelper'

import Button from 'react-bootstrap/Button'

import { FaDna, FaPhoneAlt, FaBriefcase, FaUserAlt } from 'react-icons/fa'


function OpcaoMenu({ texto, opcao, funcao, largura, altura, resolucaoMobile}) {

    function _retornarIcone(opcao) {
        const icones = {
            servicos: <FaBriefcase size={25} style={{ color: constantes.CORES.BRANCO }} />,
            quemSomos: <FaDna size={25} style={{ color: constantes.CORES.BRANCO }} />,
            contato: <FaPhoneAlt size={25} style={{ color: constantes.CORES.BRANCO }} />,
            areaCliente: <FaUserAlt size={25} style={{ color: constantes.CORES.BRANCO }} />,
        }

        return icones[opcao]
    }

    const style = {
        width: largura ? `${largura}px` : '200px',
        height: altura ? `${altura}px` : '50px',
        background: 'transparent',
        fontSize: '18px',
        border: 'none'
    }

    return (
        <Button type='submit' style={style} onClick={funcao ? funcao : () => { }} className={`d-flex align-items-center justify-content-${resolucaoMobile ? 'start' : 'center'}`}>
            {_retornarIcone(opcao)}
            <div style={{ width: '15px' }}></div>
            <span style={{ color: constantes.CORES.BRANCO }}>
                {texto}
            </span>

        </Button>
    );
}
export default OpcaoMenu;
