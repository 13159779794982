import { useState, useEffect } from 'react'

import constantes from '../helpers/constanteHelper'

import Hero from '../components/Hero'
import Servicos from '../components/Servicos'
import QuemSomos from '../components/QuemSomos'
import Contatos from '../components/Contatos'
import Rodape from '../components/Rodape'

function Home() {

    // Controles de responsividade
    const [width, setWidth] = useState(window.innerWidth);

    function observarMudancaWidth() {
        setWidth(window.innerWidth);
    }
    const resolucaoMobile = width <= constantes.RESPONSIVIDADE.LARGURA_MOBILE;

    // Processos feitos no início da tela
    useEffect(() => {

        // Scroll para o topo da página para melhor visualização no mobile ou em resoluções menores
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

        window.addEventListener('resize', observarMudancaWidth);
        return () => {
            window.removeEventListener('resize', observarMudancaWidth);
        }
    }, [])

    // Checando o scroll para ir fazendo as animações

    function reveal() {
        let reveals = document.querySelectorAll(".reveal");
        for (let i = 0; i < reveals.length; i++) {
            let windowHeight = window.innerHeight;
            let elementTop = reveals[i].getBoundingClientRect().top;
            let elementVisible = 150;
            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }

    window.addEventListener("scroll", reveal);

    reveal();

    return (
        <div style={{ width: '100vw', scrollbarGutter: 'stable both-edges' }} className='d-flex flex-column align-items-center justify-content-center'>
            <Hero resolucaoMobile={resolucaoMobile} />
            <Servicos resolucaoMobile={resolucaoMobile} />
            <QuemSomos resolucaoMobile={resolucaoMobile} />
            <Contatos resolucaoMobile={resolucaoMobile} />
            <Rodape resolucaoMobile={resolucaoMobile} />
        </div>
    );
}

export default Home;
