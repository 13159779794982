import Rotas from './rotas';
import 'animate.css'

function App() {
  return (
    <div className="App">
      <Rotas/>
    </div>

  );
}

export default App;
