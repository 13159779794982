import { useState } from 'react'

import Menu from './Menu'

import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'

import imagemFundoHero from '../images/imagem-fundo-hero.jpg'
import imagemFundoHero2 from '../images/imagem-fundo-hero-2.jpg'
import imagemFundoHero2Mobile from '../images/imagem-fundo-hero-2-mobile.jpg'

import imagemAngusHero from '../images/angus.jpg'
import imagemDurocHero from '../images/duroc.jpg'
import imagemHolandesHero from '../images/holandes.jpg'

import Botao from './Botao'


function Hero({ resolucaoMobile }) {
    return (
        <div style={{ width: '100vw', padding: 0, margin: 0, background: 'black', backgroundImage: resolucaoMobile ? `url(${imagemFundoHero2Mobile})` : `url(${imagemFundoHero2})`}} className='d-flex flex-column align-items-center'>
            <Menu />
            <div className={resolucaoMobile ? 'text-center mt-5 d-flex flex-column align-items-center justify-content-center' : 'mt-5 d-flex align-items-center justify-content-between'} style={{ width: '80vw' }}>
                <div className={resolucaoMobile ? 'd-flex flex-column align-items-center justify-content-center' : ''} style={{ width: resolucaoMobile ? '90vw' : '50vw' }}>
                    <span className='animate__animated animate__fadeInLeft' style={{ fontSize: resolucaoMobile ? '30px' : '60px', color: 'white', fontWeight: '600' }}>Tecnologia e genética a seu favor.<br /></span>
                    <span className='animate__animated animate__fadeInLeft' style={{ fontSize: resolucaoMobile ? '14px' : '25px', color: 'white', fontWeight: '300' }}>Garantia de origem e procedência. Entre em contato e se surpreenda com nossas soluções.</span>
                    <div style={{ height: '20px' }}></div>
                    <Botao texto={'Faça contato'} funcao={() => document.getElementById('contato')?.scrollIntoView()}/>
                    <div style={{ height: resolucaoMobile ? '10px' : '50px' }}></div>
                </div>
                <div className={'my-5'} style={{width: resolucaoMobile ? '420px' : '30vw', height: resolucaoMobile ? '250px' : '23vw'}} >
                    <Carousel controls={false} indicators={false} interval={3000}>
                        <Carousel.Item>
                            <Image style={{ width: resolucaoMobile ? '90vw' : '30vw', borderRadius: '15px' }} src={imagemAngusHero} fluid />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image style={{ width: resolucaoMobile ? '90vw' : '30vw', borderRadius: '15px' }} src={imagemDurocHero} fluid />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image style={{ width: resolucaoMobile ? '90vw' : '30vw', borderRadius: '15px' }}  src={imagemHolandesHero} fluid />
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
        </div >
    );
}
export default Hero;
