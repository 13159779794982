import { Image } from 'react-bootstrap'

import constantes from '../../helpers/constanteHelper'


function ServicoTile({ nome, imagem, descricao, resolucaoMobile }) {

    return (
        <div className='reveal my-5 d-flex flex-wrap flex-column align-items-center'>
            <Image style={{ borderRadius: '20px', height: resolucaoMobile ? '150px' : '200px' }} className='mx-3' src={imagem} fluid />

            <div className='mt-3 d-flex align-items-center justify-content-center' style={{ fontSize: '25px', height: '80px', width: resolucaoMobile ? '80vw' : '300px', textAlign: 'center', color: constantes.CORES.AZUL_PRIMARIO, fontWeight: '600' }}>
                <span>{nome}</span>
            </div>

            <div className='mt-2' style={{ width: resolucaoMobile ? '80vw' : '250px', textAlign: 'justify',  height: resolucaoMobile ? '100px' : '140px',  }}>
                <span style={{ fontSize: resolucaoMobile ? '17px' : '17px', color: 'black', fontWeight: '300' }}>{descricao}</span>
            </div>


        </div>
    );
}
export default ServicoTile;
