import constantes from '../helpers/constanteHelper'

import Button from 'react-bootstrap/Button'

import { FaPlusCircle } from 'react-icons/fa'

function _retornarIcone(icone) {
    const icones = {
        incluir: <FaPlusCircle size={25} />,
    }

    return icones[icone]
}

function Botao({ texto, icone, funcao, largura, altura,  cor, corTexto, desabilitado }) {
    const regraCor =  cor ? cor : constantes.CORES.SALMAO_PRIMARIO

    const style = {
        width: largura ? `${largura}px` : '200px',
        height: altura ? `${altura}px` : '50px',
        // borderRadius: '2.1875rem',
        background: regraCor,
        borderColor: regraCor,
        fontSize: '17px',
        color: corTexto ? corTexto : constantes.CORES.BRANCO_PADRAO
    }

    return (
        <Button disabled={desabilitado ? true : false} type='submit' style={style} onClick={funcao ? funcao : () => { }} className='d-flex align-items-center justify-content-center mt-3'>
            {_retornarIcone(icone)}
            <div style={{ width: icone ? '15px' : '0px' }}></div>
            {texto}
        </Button>
    );
}
export default Botao;
